import { getScopeId, getScopeIdOrThrow } from "@telia/b2b-customer-scope";
import { corpScopeInformation, corpUserAccountService } from "@telia/b2b-rest-client";
import type {
  HasAccessToScopeResponse,
  GetOrganizationResponse,
  GetScopeDetailsResponse,
  GetAvailableScopesResponse,
  GetServiceWebsResponse,
} from "@telia/b2b-rest-client/dist/corp-scope-information";

let scopeId: string | null;
let getOrganizationsPromise: ReturnType<typeof getOrganizations> | undefined;
let getLoggedInUserInfoPromise: ReturnType<typeof getLoggedInUserInfo> | undefined;
let getScopeDetailsPromise: ReturnType<typeof getScopeDetails> | undefined;
let getAvailableScopesPromise: ReturnType<typeof getAvailableScopes> | undefined;
let getServiceWebsPromise: Promise<GetServiceWebsResponse> | undefined;

async function verifyScopeId() {
  const newScopeId = await getScopeIdOrThrow();
  if (newScopeId !== scopeId) {
    scopeId = newScopeId;
    await _flush();
  }
}

export async function hasAccessToScope(): Promise<HasAccessToScopeResponse> {
  await verifyScopeId();
  return await corpScopeInformation.ScopeControllerService.hasAccessToScope(
    "LOGGED_IN_USER_NO_SCOPE",
    `${scopeId}`
  );
}

export async function getScopeDetails(): Promise<GetScopeDetailsResponse> {
  await verifyScopeId();
  if (!getScopeDetailsPromise) {
    getScopeDetailsPromise = corpScopeInformation.ScopeControllerService.getScopeDetails(
      `${scopeId}`
    );
  }

  return await getScopeDetailsPromise;
}

export async function getGroupId(): Promise<number> {
  const details = await getScopeDetails();
  if (details?.scope?.groupId) {
    return details.scope.groupId;
  } else {
    throw new Error("Group ID missing in scope");
  }
}

export async function isTeliaAdmin(): Promise<boolean> {
  const details = await getScopeDetails();
  if (details?.user?.domain) {
    return details.user.domain === "TCAD";
  } else {
    return false;
  }
}

export async function hasPermission(action: string): Promise<boolean> {
  if (!action) {
    throw new Error("No action specified");
  } else {
    const details = await getScopeDetails();
    if (details?.scope?.actions?.available) {
      return details.scope.actions.available.includes(action);
    } else {
      throw new Error("Available actions missing in scope");
    }
  }
}

export async function getAvailableScopes(): Promise<GetAvailableScopesResponse> {
  await verifyScopeId();
  if (!getAvailableScopesPromise) {
    getAvailableScopesPromise = corpScopeInformation.ScopeControllerService.getAvailableScopes(
      `${scopeId}`
    );
  }

  return await getAvailableScopesPromise;
}

/**
 * @deprecated in favor of getOrganizations()
 */
export async function getOrganisations(): Promise<any> {
  const organisations = await getOrganizations();

  return organisations?.map((organisation) => ({
    name: organisation.name,
    tscid: organisation.tscid,
    number: organisation.organizationNumber,
  }));
}

export async function getOrganizations(): Promise<GetOrganizationResponse[]> {
  await verifyScopeId();

  if (!getOrganizationsPromise && scopeId) {
    getOrganizationsPromise = corpScopeInformation.ScopeControllerService.getOrganizationsForScope(
      scopeId
    );
  }
  return (await getOrganizationsPromise) as GetOrganizationResponse[];
}

/**
 * @param allowMissingScope If false or undefined, an exception is thrown if there currently is no scope. If true, an empty list is returned instead.
 */
export async function getServiceWebs(
  allowMissingScope = false
): Promise<GetServiceWebsResponse["serviceWebs"]> {
  if (allowMissingScope && (await getScopeId()) === null) {
    return [];
  }

  await verifyScopeId();

  if (!getServiceWebsPromise && scopeId) {
    getServiceWebsPromise = corpScopeInformation.ScopeControllerService.getServiceWebs(scopeId);
  }
  return (await getServiceWebsPromise)?.serviceWebs || [];
}

export async function getLoggedInUserInfo(): Promise<any> {
  try {
    await verifyScopeId();
    if (!getLoggedInUserInfoPromise) {
      getLoggedInUserInfoPromise = corpUserAccountService.UserControllerService.getTcwssUser(
        `${scopeId}`
      );
    }
    return await getLoggedInUserInfoPromise;
  } catch (error) {
    throw new Error("User ID missing in scope");
  }
}

async function _flush(): Promise<void> {
  await Promise.allSettled([
    getLoggedInUserInfoPromise,
    getOrganizationsPromise,
    getScopeDetailsPromise,
    getAvailableScopesPromise,
    getServiceWebsPromise,
  ]);
  getLoggedInUserInfoPromise = undefined;
  getOrganizationsPromise = undefined;
  getScopeDetailsPromise = undefined;
  getAvailableScopesPromise = undefined;
  getServiceWebsPromise = undefined;
}

export async function bootstrap(): Promise<void> {
  return Promise.resolve();
}

export async function mount(): Promise<void> {
  // this is called from a lot of pages, some do not have a scope
  if ((await getScopeId()) !== null) {
    // prefetch, execute in parallell
    getOrganizations();
    getScopeDetails();
    getAvailableScopes();
  }
}

export async function unmount(): Promise<void> {
  return Promise.resolve();
}
